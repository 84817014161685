import { createStore } from 'vuex'
// import store from './store'
import { HttpManager } from '../api/index'
import {CODE} from "@/resources/common";
import {ElMessage} from "element-plus";
import {resolve} from "chart.js/helpers";

export default createStore({
  state: {
    collapse: false,
    loginStatus: false,
    user: {
      id: "",
      name: "",
      number: ""
    },
    systemName: {
      index: "英语听力推理计算机动态评估系统",
      student: "英语听力推理计算机动态评估系统",
      teacher: "听力动态评估干预系统教师端"
    },

    isEmam:false,
    map1:{},
    map2:{},
    map3:{},
    map4:{},
    map5:{},
    pointMap:{},

    answerList:[],


    //调查问卷

    examQuestion:null,

    //教师端报告:
    answerMap:{},
    classAnswerMap:{},
    titleMap:{},
    studentList: [],
    classList: [],
    studentData: {},
    examinationList: [],
    examinationData: {},
    conversationList: [],
    conversationData: {}, //编辑对话，查看对话
    paperminlist:[],
    interList:[],
    paperList: [],
    examinationScoreList: [],
    scoreDetail: [],
    feedbackList: [],
    feedbackReportData: [], // 调查问卷的内容，教师端显示用
    fQuestionList:[], // 存的是调查问卷的问题，学生端显示用
    studentExaminationList: [] ,    //学生端显示用
    currentStudentExamination:{ //当前学生考试使用的试卷
      currentStudentParer:[],//试卷的总体信息
      currentConversationList:[], //当前学生考试使用试卷的大题
      currentPaperMinList:[],//当前学生考试试卷的小题题
      currentMinInterveneList:[],//干预
    },
    feedbackMessageList: [],
    personalMessageList: [], //个人中心的资料
    paperViewList:[],//试卷视图
    testPaperList:[],//所有考试列表
  },
  getters: {
  },
  mutations: {


    updateIsExam(state,newValue){
      state.isEmam=newValue;
    },


    updateExamQuestion(state,newValue){
      state.examQuestion=newValue;
    },
    updateAnswerMap(state,newValue){
      state.answerMap=newValue;
    },
    updateClassAnswerMap(state,newValue){
      state.classAnswerMap=newValue;
    },
    updateTitleMap(state,newValue){
      state.titleMap=newValue;

    },

    updateMap1(state,newValue){
      state.map1=newValue;
    },
    updateMap2(state,newValue){
      state.map2=newValue;
    },
    updateMap3(state,newValue){
      state.map3=newValue;
    },
    updateMap4(state,newValue){
      state.map4=newValue;
    },
    updateMap5(state,newValue){
      state.map5=newValue;
    },
    updateAnswerList(state,newValue){
      state.answerList=newValue;
    },
    updatePointMap(state,newValue){
      state.pointMap=newValue;
    },

    updateCurrentStudentExaminationPaper(state,newValue){
      state.currentStudentExamination.currentStudentParer = newValue
    },
    updateCurrentStudentExaminationConversationList(state,newValue){
      state.currentStudentExamination.currentConversationList = newValue
    },
    updateCurrentStudentExaminationMinInterveneList(state,newValue){
      state.currentStudentExamination.currentMinInterveneList.push(newValue)
    },
    updateCurrentStudentExaminationPaperMinList(state,newValue){
      state.currentStudentExamination.currentPaperMinList.push(newValue)
    },
    updateTestPaper(state,newValue){
      state.testPaperList = newValue
    },
    updatepaperminlist(state,newValue){
      state.paperminlist=newValue
    },
    updateInterList(state,newValue){
      state.interList=newValue
    },
    updateUser(state, newValue) {
      state.user = newValue
    },
    updateCollapse(state, newValue) {
      state.collapse = newValue
    },
    updateStudentList(state, newValue) {
      state.studentList = newValue
    },
    updateClassList(state, newValue) {
      state.classList = newValue
    },
    updateStudentData(state, newValue) {
      state.studentData = newValue
    },
    updateExaminationList(state, newValue) {
      state.examinationList = newValue
    },
    updateExaminationData(state, newValue) {
      state.examinationData = newValue
    },
    updateConversationList(state, newValue) {
      state.conversationList = newValue
    },
    updateConversationData(state, newValue) {
      state.conversationData = newValue
    },
    updatePaperList(state, newValue) {
      state.paperList = newValue

    },
    updateExaminationScoreList(state, newValue) {
      state.examinationScoreList = newValue
    },
    updateScoreDetail(state, newValue) {
      state.scoreDetail = newValue
    },
    updateFeedbackList(state, newValue) {
      state.feedbackList = newValue
    },
    updateFeedbackReportData(state, newValue) {
      state.feedbackReportData = newValue
    },
    updateLoginStatus(state, newValue) {
      state.loginStatus = newValue
    },
    updateFQuestionList(state, newValue) {
      state.fQuestionList = newValue
    },
    updateStudentExaminationList(state, newValue) {
      state.studentExaminationList = newValue
    },
    updateFeedbackMessageList(state, newValue) {
      state.feedbackMessageList = newValue
    },
    updatePersonalMessageList(state, newValue) {
      state.personalMessageList = newValue
    },
    updatePaperViewList(state, newValue) {
      state.paperViewList = newValue
    },
  },
  actions: {
    getStudentList(context, payload) {
      return new Promise(async (resolve, reject) => {

        await HttpManager.getStudents(payload)
            .then(res => {
              if (res.code === CODE.OK_200) {
                resolve(res)
                // console.log(res.hashmap.hasNext)
                context.commit('updateStudentList', res.hashmap.Students)
              } else {

                reject('分页查询学生失败')
              }
            }).catch((error) => {
              reject(error)
            })
      })
    },
    getTestPaperList(context) {
      return new Promise(async (resolve, reject) => {
        await HttpManager.getTestPaper()
            .then((res) => {
              if (res.code == CODE.OK_200) {
                resolve(res)
                context.commit('updateTestPaper', res.hashmap.Exams)
              } else {
                reject('分页查询考试列表失败')
              }
            }).catch((error) => {
              reject(error)
            })
      })
    },
    getClassList(context, payload) {
      return new Promise(async (resolve, reject) => {
        await HttpManager.getStudentClassPage()
            .then((res) => {
              if (res.code == CODE.OK_200) {
                context.commit('updateClassList', res.hashmap.StudentsClass)
              } else {
                reject('分页查询班级失败')
              }
            }).catch((error) => {
              reject(error)
            })
      })
    },
    getStudentById(context, params) {
      HttpManager.getStudentById(params).then((res) => {
            if (res.code == CODE.OK_200) {
              console.log(res)
              context.commit("updateStudentData", res)
            } else {
              ElMessage.error("查询不到");
              console.log("这块错了")
            }
          }
      )
    },
    getExaminationList(context, payload) {

      console.log(payload)
      // 异步请求后台临时假数据
      const mockResponseData = [{
        id: 1,
        examinationName: "test1",
        startTime: "2022/12/01 21:49:13",
        endTime: "2022/12/03 21:49:13",
        // 0表示未开始， 1表示进行中， 2表示已结束
        examinationState: 0,
        pId: 1,
        pTypes: '0'
      }, ]
      // 请求到数据后保存在store中
      context.commit('updateExaminationList', mockResponseData)
    },
    getExaminationById({commit, state}, payload) {
      const resData = state.examinationList.find(item => {
        return item.id == payload.id
      })
      console.log(state.examinationData)
      commit("updateExaminationData", resData)
    },
    async getCurrentStudentExamination(context, payload){
      console.log(context.state.studentExaminationList)
      console.log(payload.eId)
      const resData = context.state.studentExaminationList
          .find(item => {
            return item.num === payload.eId
          })
      console.log(resData.num)
      if(resData){
        context.commit("updateCurrentStudentExaminationPaper", resData)
        console.log(context.state.currentStudentExamination)
        const param1={
          num:resData.num
        }
        //获取考试试卷的大题
        await HttpManager.getConversationList(param1)
            .then((res) => {
              console.log(res)
              console.log(res)
              console.log(resData.num)
              if (res.code === CODE.OK_200) {
                context.commit('updateCurrentStudentExaminationConversationList', res.hashmap.paper)
                console.log(res.hashmap.paper)
              } else {
                ElMessage.warning("出错了")
              }
            })
        for (var i in context.state.currentStudentExamination.currentConversationList){
          var param2={
            num:context.state.currentStudentExamination.currentConversationList[i].num,
            titleNum:context.state.currentStudentExamination.currentConversationList[i].titleNum}
          console.log(param2)
          await HttpManager.getPaperMinList(param2) .then(async (res) => {
            if (res.code === CODE.OK_200) {
              if (res.hashmap.paperMinList.length !== 0)
                context.commit('updateCurrentStudentExaminationPaperMinList', res.hashmap.paperMinList);
              for (var j = 0; j < res.hashmap.paperMinList.length;j++) {
                var param3 = {
                  num: context.state.currentStudentExamination.currentConversationList[i].num,
                  titleNum: context.state.currentStudentExamination.currentConversationList[i].titleNum,
                  titleMinNum: res.hashmap.paperMinList[j].titleMinNum
                }
                console.log(param3)
                await HttpManager.getPaperInterveneList(param3).then((res) => {
                  if(res.code===CODE.OK_200){
                    if(res.hashmap.paperIntervene.length!==0)
                      context.commit("updateCurrentStudentExaminationMinInterveneList",res.hashmap.paperIntervene)
                  }
                  else {
                    ElMessage.warning("获取干预出错了")
                  }
                })
              }
            } else {
              ElMessage.warning("获取小题出错了")
            }
          })


        }
        console.log(context.state.currentStudentExamination)


        //获取考试试卷的干预
        const requestParams = {
          num:resData.num,//试卷号
          //titleNum:context.state.currentStudentExamination.currentConversationList.titleNum,//大题号
          // isOnLine:,//是否在线播放
        }
      }else{
        console.log('未找到该试卷')
      }
    },
    async getConversationList(context, payload) {
      await HttpManager.getConversationList(payload)
          .then((res) => {
            if (res.code === CODE.OK_200) {
              context.commit('updateConversationList', res.hashmap.paper)
              console.log(res.hashmap.paper)
            } else {
              ElMessage.warning("出错了")
            }
          })
    },
    //获取
    getInterList(context,payload){
      return new Promise(async (resolve,reject)=>{
        await HttpManager.getPaperInterveneList(payload).then((res)=>{
              if(res.code===CODE.OK_200){

                context.commit("updateInterList",res.hashmap.paperIntervene)
              }
              else {
                ElMessage.warning("出错啦")
                reject("获取干预列表出错")
              }
            }
        ).catch((error) => {
          console.log(error)
        })
      })
    },


    getPaperMinList(context, payload) {
      return new Promise(async (resolve, reject) => {
        await HttpManager.getPaperMinList(payload).then((res) => {
              if (res.code === CODE.OK_200) {
                context.commit('updatepaperminlist', res.hashmap.paperMinList)
                console.log(res.hashmap.paperMinList)
              } else {
                ElMessage.warning("出错啦")
                reject("获取小题列表失败")
              }
            }
        ).catch((error) => {
          console.log(error)
        })
      })
    },
    // 请求到数据后保存在store中
    getConversationByCId({commit, state}, payload) {
      const resData = state.conversationList.find(item => {
        return item.titleNum == payload.titleNum
      })
      console.log(resData)

      commit("updateConversationData", resData)
    },
    getConversationByCIds({state}, payload) {
      return new Promise((resolve, reject) => {
        const cIds = payload
        if (cIds && cIds.length > 0) {
          const conversations = cIds.map((cId) => {
            const conversationObj = state.conversationList.find((element) => (element.cId == cId))
            if (conversationObj) {
              return conversationObj
            }
          })
          resolve(conversations)
        } else {
          reject('您没有选择对话')
        }
      })
    },
    //获取试卷视图
    async getPaperList(context, payload) {
      await HttpManager.getPaperViews(payload)
          .then((res) => {
            if (res.code === CODE.OK_200) {
              context.commit('updatePaperList', res.hashmap.PaperView)

            } else {
              ElMessage.warning("出错了")
            }
          })
    },
    getExaminationScoreList(context, payload) {
      // 请求到数据后保存在store中
      context.commit('updateExaminationScoreList', mockResponseData)
    },
    getScoreDetailByEIdAndSId(context, payload) {
      console.log(payload)
      const mockResponseData = [

      ]
      context.commit('updateScoreDetail', mockResponseData)
    },
    getFeedbackList(context, payload) {
      console.log(payload)
      const mockResponseData = [
        {
          sId: 1,
          name: 'Tom1',
          number: '04183114',
          submitTime: '2022/12/01 21:49:13',
          flag: 1
        },

      ]
      context.commit('updateFeedbackList', mockResponseData)
    },
    getFeedbackReportDataBySId(context, payload) {
      console.log(payload)
      const mockResponseData = [
        {
          fqId: 1,
          fQuestion: 'The C-DA system is preferable to the traditional listening tasks.',
          answer: '1'
        },

      ]
      context.commit('updateFeedbackReportData', mockResponseData)
    },
    getFQuestionList(context, payload) {
      console.log(payload)
      const mockResponseData = [
        {
          fqId: 1,
          fQuestion: 'The C-DA system is preferable to the traditional listening tasks.'
        },

      ]
      context.commit('updateFQuestionList', mockResponseData)
    },
    //有问题，好像可以不要 getTestingPaperViewList 已经代替了
    getExaminationListBySId(context, payload) {
      return new Promise(async (resolve, reject) => {

        await HttpManager.getTestingPaperViewList()
            .then((res) => {
              if (res.code == CODE.OK_200) {
                resolve(res)
                context.commit('updateStudentExaminationList', res.hashmap.TextingPaper)

              } else {
                reject('查询失败')
              }
            }).catch((error) => {
              reject(error)
            })
      })
      // 请求到数据后保存在store中

    },
    getFeedbackMessageListByEIdAndSId(context, payload) {
      console.log(payload)
      const mockResponseData = [
        {
          cId: 1,
          cName: "1",
          cQuestions: [
            {
              qId: 1,
              question: "What does the woman imply about the people who work in the payroll office?",
              questionThought: '我是答题思路1',
              hintSatisfaction: '我是提示的满意度1'
            },

          ],
        },

      ]
      context.commit('updateFeedbackMessageList', mockResponseData)
    },

    getPaperViewList(context, payload) {
      HttpManager.getPaperViews()
          .then((res) => {
                if (res.data == CODE.OK_200) {
                  console.log(res)
                  return res
                  context.commit("updatePaperViewList", res)
                } else {
                  ElMessage.error("查询不到试卷视图");
                  console.log("这块错了")
                }
              }
          )
    },
  },
  modules: {
  }
})
